<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine the change in standard entropy,
        <stemble-latex content="$\Delta \text{S}^\circ ,$" />
        for the two reactions shown below using the thermodynamic data provided
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >.
      </p>

      <p class="mb-3">
        <stemble-latex content="$\ce{a) 2 CO(g) + O2(g) -> 2 CO2(g)}$" />
      </p>

      <calculation-input
        v-model="inputs.Srxn1"
        class="mb-8"
        prepend-text="$\Delta\text{S}^{\circ}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\ce{b) 3 H2(g) + N2(g) -> 2 NH3(g)}$" />
      </p>

      <calculation-input
        v-model="inputs.Srxn2"
        class="mb-4"
        prepend-text="$\Delta\text{S}^{\circ}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question279',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Srxn1: null,
        Srxn2: null,
      },
    };
  },
};
</script>
